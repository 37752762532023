.about_container {
    display: grid;
    gap: 15%;
}

.about_card > h5 {
    font-size: 1.2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.about_card > p {
    font-size: 1rem;
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.about_card {
background: var(--color-bg-variant);
border: 1px solid transparent;
border-radius: 1rem;
padding: 2rem;
text-align: center;
margin: 1rem;
}

/* For Medium Devices (IE - Tablets) */
@media screen and (max-width: 1040px) {
}

/* For Small Devices (IE - Phones) */
@media screen and (max-width: 600px) {
    .about_cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
    }

}

