.portfolio_container {
    display: grid;
    gap: 15%;
}

.portfolio_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.portfolio_card {
    background: var(--color-bg);
    opacity: 1;
    background-size: 6px 6px;
    background-image: repeating-linear-gradient(45deg, #2c2c6c 0, #2c2c6c 0.6000000000000001px, #1f1f38 0, #1f1f38 50%);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

/* For Medium Devices (IE - Tablets) */
@media screen and (max-width: 1040px) {
    .portfolio_cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
    }
}

/* For Small Devices (IE - Phones) */
@media screen and (max-width: 600px) {
    .portfolio_cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
    }

}
