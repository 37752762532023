footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

.footer_logo {
    width: 24rem;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

footer a {
    color: var(--color-bg);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
    padding: 2rem;
}

.footer_social {
    padding: 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
}

.footer_copyright {
    padding: 2rem;
    margin-bottom: 2rem;
    color: var(--color-bg);
    text-align: center;
}

/* For Small Devices (IE - Phones) */
@media screen and (max-width: 600px) {
    .permalinks {
        gap: 1rem;
        flex-wrap: inherit;
    }
    .footer_logo {
        width: 20rem;
    }
}